import React from "react"
import Btn from "./Btn"

const Banner = ({heading, subtitle, caption, btnText, btnUrl, btnIsLink, location, className, children}) => {

	const btnLinkType = () => {
		(btnIsLink === true) ? 'isLink' : ''
	}

	const getLocation = [
		location ? ( location ) : ( 'page' )
	]

	let bannerClassName = `${getLocation}-banner`;
	if ( className ) bannerClassName += ' ' + className;

	return (

		<>
		<div className={`flex flex-row md:flex-nowrap flex-wrap items-center ${bannerClassName}`}>
			<div className="w-full md:w-1/2 md:flex-1/2 md:pr-10 lg:pr-12 order-2 md:order-1">

				{ subtitle &&
					<pre className="m-0 p-0 pl-2 -mb-0.5 text-xs md:text-xs lg:text-sm font-normal text-black my-2 tracking-widest font-sans uppercase"
						style={{letterSpacing: '4px'}}

						>
						{subtitle}
					</pre>
				}

			{ heading &&
				<h2 className="text-6xl md:text-7xl lg:text-8xl xl:text-9xl xl:leading-[92%] xl:mt-2 xl:mb-10 font-thin leading-none font-brand">
					{heading}
				</h2>
			}

			{ caption &&
				<p
				dangerouslySetInnerHTML={{__html: caption}}
				className="mt-5 mb-8 lg:px-8 xl:px-14"
				/>


			}

			{ (btnText && btnUrl && btnIsLink !== true) &&

				<Btn linkTo={btnUrl} buttonTitle={btnText} />

			}

			{ (btnText && btnUrl && btnIsLink === true) &&

				<Btn linkTo={btnUrl} buttonTitle={btnText} isLink />

			}

			</div>
			<div className="w-full md:w-1/2 md:flex-1/2 md:pl-10 lg:pl-16 order-1 md:order-2 md:pb-0 pb-12">

			{children}
			</div>
		</div>
		</>

	)
}

export default Banner
