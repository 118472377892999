import React, { useState } from 'react'
import Banner from './Banner'
// import musicData from '../data/music-data'
// import { isTodayOrAfter } from '../utils/isTodayOrBefore'
import Fade from 'react-reveal'
import { single1Data, single2Data, albumData } from '../data/homepage-data'
import { egyptLakeReleaseDate, redEarthCreekReleaseDate, hohnAlbumDate } from '../data/releaseDate-data';
import { formatMyDate } from '../utils/formatMyDate'

export default function HomepageBanner() {

    let musicData = single1Data

    // const albumDate = new Date('2022-11-11T00:00:00')
    // const single2Date = new Date('2022-10-28T00:00:00')
    // const single1Date = new Date('2022-10-07T00:00:00')
    const albumDate = hohnAlbumDate
    const single2Date = redEarthCreekReleaseDate
    const single1Date = egyptLakeReleaseDate


    const now = new Date()


    // if (single2Date.toLocaleDateString() < now.toLocaleString()) musicData = single2Data
    // else if (albumDate.toLocaleDateString() < now.toLocaleString()) musicData = albumData
    if (single2Date <= now) musicData = single2Data
    if (albumDate <= now) musicData = albumData

    // console.log('New Date: ' + now)
    // console.log('New Locale: ' + now.toLocaleString())

    return (<>
    
    {musicData.map(song => {

    // const fallbackCaption = single1Date.toLocaleString() < now.toLocaleString() ? song.caption : `The debut single from Höhn.`
    // const fallbackSubtitle = single1Date.toLocaleString() < now.toLocaleString() ? song.subtitle : `Coming ${formatMyDate(single1Date)}`;
    // const fallbackBtnUrl = single1Date.toLocaleString() < now.toLocaleString() ? song.linkPath : null
    const fallbackCaption = single1Date < now ? song.caption : `The debut single from Höhn.`
    const fallbackSubtitle = single1Date < now ? song.subtitle : `Coming ${formatMyDate(single1Date)}`;
    const fallbackBtnUrl = single1Date < now ? song.linkPath : null

        return (
            <Banner key={song.id}
                    heading={song.title}
                    caption={fallbackCaption}
                    subtitle={fallbackSubtitle}
                    btnText={song.linkTitle}
                    btnIsLink={false}
                    btnUrl={fallbackBtnUrl}
                    location="homepage"
                    className={`text-center`}
                    
                    >
                    {song.image &&

                        <Fade duration={400}>
                            {song.image}
                        </Fade>
                    
                    }
                
            </Banner>
        )
        })}
    </>)
}