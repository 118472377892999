import React from 'react';
import Seo from '../components/layout/Seo'
import HomepageBanner from '../components/HomepageBanner';
import Layout from '../components/layout/Layout';
import Section from '../components/layout/Section'
import SplitBackground from '../svg/SplitBG'

const HomePage = () => {

  return (
    <Layout>
      <Seo title="Home" />

      <Section sectionName="intro" containerSize={`xl`}>
      <div className="bg-transparent block md:min-h-[50vh] lg:min-h-[80vh]">

      <HomepageBanner />

	    {/* <Banner heading={`Let the Natural World Speak`} subtitle={`Debut LP Höhn Out Now`}
              caption={`Ambient and lucid, an ethereal trance, you plug in, unclench, submit to becoming unknown in the wild and return to your instincts.`}
	            btnText="find a stream"
	            btnIsLink={true}
	            btnUrl="/music#hohn"
              location="homepage"
              className={`text-center`}
	            >
        <Fade duration={400}>
          <StaticImage
              src="../images/music/hohn_album_cover_art_web.jpg"
              quality={75}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="hohn"
              className="shadow-xl"
            />
        </Fade>
	    </Banner> */}
      </div>
      </Section>

      <SplitBackground />

    </Layout>
  )
}

export default HomePage;
