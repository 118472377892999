import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { nanoid } from 'nanoid'

export default [
    {
        title: `Egypt Lake`,
        subtitle: `Debut Single Out Now`,
        caption: `Listen to it today.`,
        linkTitle: `Stream Now`,
        linkPath: `http://stns.ffm.to/EgyptLake`,
        image: <StaticImage
            src="../images/music/hohn_single_2_cover_art_web.jpg"
            quality={75}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Album cover for Egypt Lake by Hohn"
            className="shadow-lg"
        />
    },
    {
        title: `Red Earth Creek Out Everywhere`,
        subtitle: `New Single from Höhn`,
        caption: `Find a stream today—wherever you listen to music.`,
        linkTitle: `Listen Now`,
        linkPath: `http://stns.ffm.to/redearthcreek`,
        image: <StaticImage
            src="../images/music/hohn_single_1_cover_art_web.jpg"
            quality={75}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Album cover for CCH by Hohn"
            className="shadow-lg"
        />
    },
    {
        title: `Let the Natural World Speak`,
        subtitle: `Debut LP Höhn Out Now`,
        caption: `Ambient and lucid, an ethereal trance, you plug in, unclench, submit to becoming unknown in the wild and return to your instincts.`,
        linkTitle: `Find a Stream`,
        linkPath: `http://stns.ffm.to/HOHN`,
        image: <StaticImage
            src="../images/music/hohn_album_cover_art_web.jpg"
            quality={75}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Hohn LP Album Cover"
            className="shadow-lg"
        />
    }
]

export const single1Data = [
    {
        title: `Egypt Lake`,
        subtitle: `Debut Single Out Now`,
        caption: `Listen to it today.`,
        linkTitle: `Stream Now`,
        linkPath: `http://stns.ffm.to/EgyptLake`,
        image: <StaticImage
            src="../images/music/hohn_single_2_cover_art_web.jpg"
            quality={75}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="hohn album cover"
            className="shadow-lg"
        />,
        id: nanoid()
    }
]

export const single2Data = [
    {
        title: `Red Earth Creek Out Everywhere`,
        subtitle: `New Single from Höhn`,
        caption: `Find a stream today.`,
        linkTitle: `Listen Now`,
        linkPath: `http://stns.ffm.to/redearthcreek`,
        image: <StaticImage
            src="../images/music/hohn_single_1_cover_art_web.jpg"
            quality={75}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="hohn album cover"
            className="shadow-lg"
        />,
        id: nanoid()
    }
]

export const albumData = [
    {
        title: `Let the Natural World Speak`,
        subtitle: `Debut LP Höhn Out Now`,
        caption: `Ambient and lucid, an ethereal trance, you plug in, unclench, submit to becoming unknown in the wild and return to your instincts.`,
        linkTitle: `Find a Stream`,
        linkPath: `http://stns.ffm.to/HOHN`,
        image: <StaticImage
            src="../images/music/hohn_album_cover_art_web.jpg"
            quality={75}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="hohn album cover"
            className="shadow-lg"
        />,
        id: nanoid()
    }
]